import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const articles = [
  {
    title: "What’s Up, Joeli? Oct. 18-Oct. 24",
    image: (
      <StaticImage
        src="../images/news/What’s Up, Joeli_ Oct. 18-Oct. 24 _ UMKC Roo News.jpg"
        title="What’s Up, Joeli? Oct. 18-Oct. 24"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    link: "https://info.umkc.edu/unews/whats-up-joeli-oct-18-oct-24/",
  },
  {
    title: "What’s Up, Joeli? Oct. 11-Oct. 17",
    image: (
      <StaticImage
        src="../images/news/What’s Up, Joeli_ Oct. 11-Oct. 17 _ UMKC Roo News.jpg"
        title="What’s Up, Joeli? Oct. 11-Oct. 17"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    link: "https://info.umkc.edu/unews/whats-up-joeli-oct-11-oct-17/",
  },
  {
    title:
      "Alumni association raises money for Kangaroo Pantry, helps provide for students in need",
    image: (
      <StaticImage
        src="../images/news/Alumni association raises money for Kangaroo Pantry, helps provide for students in need _ UMKC Roo News.jpg"
        title="Alumni association raises money for Kangaroo Pantry, helps provide for students in need"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    link: "https://info.umkc.edu/unews/alumni-association-raises-money-for-kangaroo-pantry-helps-provide-for-students-in-need/",
  },
  {
    title: "What's Up, Joeli? Oct. 4 - Oct. 10",
    image: (
      <StaticImage
        src="../images/news/What_s Up, Joeli_ Oct. 4-Oct. 10 _ UMKC Roo News.jpg"
        title="What_s Up, Joeli_ Oct. 4-Oct. 10"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    link: "https://info.umkc.edu/unews/whats-up-joeli-oct-4-oct-10/",
  },
  {
    title: "What’s Up, Joeli Sept. 27 - Oct. 1 ",
    image: (
      <StaticImage
        src="../images/news/What’s Up, Joeli_ Sept. 27-Oct. 1 _ UMKC Roo News.jpg"
        title="What’s Up, Joeli_ Sept. 27-Oct. 1 _ UMKC Roo News"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    link: "https://info.umkc.edu/unews/whats-up-joeli/",
  },
  {
    title: "Kansas City healthcare workers receive coronavirus vaccines",
    image: (
      <StaticImage
        src="../images/news/Kansas City healthcare workers receive coronavirus vaccines _ University News.jpg"
        title="Kansas City healthcare workers receive coronavirus vaccines"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    link: "https://info.umkc.edu/unews/kansas-city-healthcare-workers-receive-coronavirus-vaccines/",
  },
  {
    title: "Johnson Hall floods due to running shower",
    image: (
      <StaticImage
        src="../images/news/Johnson Hall floods due to running shower _ University News.jpg"
        title="Johnson Hall floods due to running shower"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    link: "https://info.umkc.edu/unews/johnson-hall-floods-due-to-running-shower/",
  },
  {
    title: "Sound of Metal review_ When the world fades into silence",
    image: (
      <StaticImage
        src="../images/news/“Sound of Metal” review_ When the world fades into silence _ University News.jpg"
        title="Sound of Metal” review_ When the world fades into silence"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    link: "https://info.umkc.edu/unews/sound-of-metal-review-when-the-world-fades-into-silence/",
  },
  {
    title: "iCarly” on Netflix is bringing nostalgia to old viewers",
    image: (
      <StaticImage
        src="../images/news/“iCarly” on Netflix is bringing nostalgia to old viewers _ University News.jpg"
        title="iCarly” on Netflix is bringing nostalgia to old viewers"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    link: "https://info.umkc.edu/unews/icarly-on-netflix-is-bringing-nostalgia-to-old-viewers/",
  },
  {
    title: "Mayor Lucas reaches deal with KC Homeless Union to remove camps",
    image: (
      <StaticImage
        src="../images/news/Mayor Lucas reaches deal with KC Homeless Union to remove camps _ University News.jpg"
        title="Mayor Lucas reaches deal with KC Homeless Union to remove camps"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    link: "https://info.umkc.edu/unews/mayor-lucas-reaches-deal-with-kc-homeless-union-to-remove-camps/",
  },
  {
    title: "UMKC plans return to in-person classes this fall",
    image: (
      <StaticImage
        src="../images/news/UMKC plans return to in-person classes this fall _ University News.jpg"
        title="UMKC plans return to in-person classes this fall"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    link: "https://info.umkc.edu/unews/umkc-plans-return-to-in-person-classes-this-fall/",
  },
  {
    title:
      "UMKC professor offers a sociological perspective on the Pfizer FDA approval",
    image: (
      <StaticImage
        src="../images/news/UMKC professor offers a sociological perspective on the Pfizer FDA approval _ UMKC Roo News.jpg"
        title="UMKC professor offers a sociological perspective on the Pfizer FDA approval"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    link: "https://info.umkc.edu/unews/umkc-professor-offers-a-sociological-perspective-on-the-pfizer-fda-approval/",
  },
  {
    title: "UMKC Students Share Advice With Peers",
    image: (
      <StaticImage
        src="../images/news/UMKC Students Share Advice With Peers - University News.jpg"
        title="UMKC Students Share Advice With Peers"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    link: "https://info.umkc.edu/unews/umkc-students-share-advice-with-peers/",
  },
  {
    title:
      "UMKC Theatre and The Coterie Theatre present “Brainstorm_ The Inside Life of the Teenage Mind",
    image: (
      <StaticImage
        src="../images/news/UMKC Theatre and The Coterie Theatre present “Brainstorm_ The Inside Life of the Teenage Mind” _ University News.jpg"
        title="UMKC Theatre and The Coterie Theatre present “Brainstorm_ The Inside Life of the Teenage Mind"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    link: "",
  },
  {
    title:
      "UMKC Theatre presents “Antigonick,” a retelling of an ancient classic",
    image: (
      <StaticImage
        src="../images/news/UMKC Theatre presents “Antigonick,” a retelling of an ancient classic _ University News.jpg"
        title="UMKC Theatre presents “Antigonick,” a retelling of an ancient classic"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    link: "https://info.umkc.edu/unews/umkc-theatre-presents-antigonick-a-retelling-of-a-ancient-classic/",
  },
  {
    title:
      "University of Kansas Health System joins local hospitals in vaccination requirements for workers, students, volunteers",
    image: (
      <StaticImage
        src="../images/news/University of Kansas Health System joins local hospitals in vaccination requirements for workers, students, volunteers _ UMKC Roo News.jpg"
        title="University of Kansas Health System joins local hospitals in vaccination requirements for workers, students, volunteers"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    link: "https://info.umkc.edu/unews/university-of-kansas-health-system-joins-local-hospitals-in-vaccination-requirements-for-workers-students-volunteers/",
  },
  {
    title:
      "Vaccines are becoming more available in Kansas City, here’s how to get in line for yours",
    image: (
      <StaticImage
        src="../images/news/Vaccines are becoming more available in Kansas City, here’s how to get in line for yours _ University News.jpg"
        title="Vaccines are becoming more available in Kansas City, here’s how to get in line for yours"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    link: "https://info.umkc.edu/unews/vaccines-are-becoming-more-available-in-kansas-city-heres-how-to-get-in-line-for-yours/",
  },
];
